import React from "react"

import Section from "../components/section/Section"
import noContentImage from "../assets/images/common/no-content-image.jpg"
import { Imgs } from "../@types/common"

const bannerBlock = ({ /* className, */ data, imgs }: { className: string; data: any; imgs: Imgs }) => {
  const { title, images, text, buttons } = data.data.content.dataset.bannerBlock

  return (
    <Section className={`sova-section sova-section-bannerBlock  sova-fullscreen-height`}>
      <div className="sova-section-container">
        <h1 className="sova-section-child sova-h2 sova-h2_banner">{title}</h1>
        <p className="sova-section-child sova-section-paragraph sova-p-tiny sova-common-text-centered">{text}</p>
        <div className="sova-btn-group_banner">
          {buttons.map((btn: any, index: number) => (
            <a href={btn.btnLink} target="blank" key={index}>
              <button className="sova-btn sova-btn_primary sova-btn_big sova-btn_banner">{btn.btnText}</button>
            </a>
          ))}
        </div>
      </div>
      <div className="sova-section-container sova-section-container_row sova-section-container__mobile">
        {images.map(({ imgSrc, imgAlt }: { imgSrc: any; imgAlt: string }, key: number) => (
          <img
            key={key}
            src={imgs[imgSrc] || noContentImage}
            alt={imgAlt}
            className={`sova-section-img sova-section-img_bannerBlock sova-section-img_bannerBlock-${key} sova-section-img_bannerBlock-${key}_up-${buttons.length > 1}`}
          />
        ))}
      </div>
    </Section>
  )
}

export default bannerBlock
