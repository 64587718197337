import React from "react"
import { Link } from "gatsby"

import Section from "../components/section/Section"
import { Imgs } from "../pagesStructures/asrTts/asrTtsSections/AsrTtsSectionFirst"

const callToActionBlockFirst = ({ className, data, images }: { className: string; data: any; images: Imgs }) => {
  const { heading, title, paragraph, buttons, imgSrc, imgAlt, link } = data.data.content.dataset.callToActionBlockFirst

  return (
    <Section className={className}>
      <div className="sova-section-container">
        {title && <h2 className="sova-section-child sova-h2">{title}</h2>}
        <div className="sova-call-to-action-block-first">
          <img className={`sova-section-child sova-section-img sova-section-call-to-action-img`} src={images[imgSrc]} alt={imgAlt} />
          <div className="sova-call-to-action-block-first-content">
            {heading && <h3 className="sova-call-to-action-block-first-h2">{heading}</h3>}
            <p className="sova-section-child sova-section-paragraph sova-p-tiny sova-common-text-left sova-call-to-action-block-first-paragraph">{paragraph}</p>
            <div className="sova-call-to-action-block-first-btns">
              {buttons.map((btn: any, i: number) =>
                btn.foreignLink ? (
                  <a href={`${btn.btnLink}`} key={i} target="blank">
                    <button className="sova-btn sova-btn_primary sova-btn_big sova-call-to-action-block-first-btn">{btn.btnText}</button>
                  </a>
                ) : (
                  <Link to={`/${btn.btnLink}`} key={i}>
                    <button className="sova-btn sova-btn_primary sova-btn_big sova-call-to-action-block-first-btn">{btn.btnText}</button>
                  </Link>
                )
              )}
            </div>
          </div>
        </div>
        {link && (
          <div className="sova-section-child-container_puzzlelib-5 sova-product-link">
            <a target="blank" href={link?.href}>
              {link?.text}
            </a>
          </div>
        )}
      </div>
    </Section>
  )
}

callToActionBlockFirst.displayName = "callToActionBlockFirst"

export default callToActionBlockFirst
