import React from "react"

import { RandomObject } from "../../@types/common"
import Section from "../../components/section/Section"
import { Imgs } from "../../pagesStructures/asrTts/asrTtsSections/AsrTtsSectionFirst"

export const InfoImg = ({ className, data, images }: { className: string; data: Array<RandomObject>; images: Imgs }) => {
  const isSingle = data.length === 1

  return (
    <Section className={`${className} info-img_section`}>
      {data.map(({ heading, paragraphs, imgSrc, imgAlt }, i) => (
        <div className={`sova-section-container info-img_single-${isSingle}`} key={i}>
          <h2 className="sova-section-child sova-h2">{heading}</h2>
          <div className={`sova-section-child-container sova-section-child-container_row sova-section-img_reverse_single-${!isSingle && i === 0}`}>
            <img className={`sova-section-child sova-section-img sova-section-img_info-img sova-section-img_info-img_single-${isSingle}`} src={images[imgSrc]} alt={imgAlt} />
            <div className={`info-img-paragraph-group_${i}`}>
              {paragraphs.map((paragraph: string, index: number) => (
                <p className={`sova-section-paragraph sova-section-child-text sova-p-tiny info-img-p_${index}`} key={index}>
                  {paragraph}
                </p>
              ))}
            </div>
          </div>
        </div>
      ))}
    </Section>
  )
}

export default InfoImg
