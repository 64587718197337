import React from "react"

import Section from "../components/section/Section"
import Slider from "../components/slider/Slider"
import { Imgs } from "../@types/common"

const InfoBlock = ({ className, data, images }: { className: string; data: any; images: Imgs }) => {
  const { heading, cards } = data.data.content.dataset.infoBlock

  const sliderOptions = cards.map((card: any) => {
    return { buttonHref: card.buttonHref, buttonText: card.buttonText, imgSrc: images[card.imgSrc], text: card.text, title: card.title }
  })

  return (
    <Section className={`sova-section ${className}`}>
      <h2 className="sova-h2 sova-section-child">{heading}</h2>
      <Slider options={sliderOptions.slice(0, 6)} className="sova-section-child sova-section-infoBlock_slider " />
      <div className={`sova-section-child info-cards`}>
        {cards.map((el: any, i: number) =>
          cards.length === i + 1 && i % 3 === 0 ? (
            <div className="sova-card-content info-cards_card_last" key={i}>
              <div className="info-cards_card_last_image">
                <img src={images[el.imgSrc]} className="sova-card-image sova-section-img" />
              </div>

              <div className="infoBlock-last-card_description">
                <p className="sova-card-header sova-p-medium">{el.title}</p>
                <p className="sova-card-description sova-p-tiny">{el.text}</p>
              </div>
            </div>
          ) : (
            <div key={i} className="info-cards_card">
              <div className={`sova-card-content`}>
                <img src={images[el.imgSrc]} className="sova-card-image sova-section-img sova-image_medium" />
                <p className="sova-card-header sova-p-medium">{el.title}</p>
                <p className="sova-card-description sova-p-tiny">{el.text}</p>
              </div>
            </div>
          )
        )}
      </div>
    </Section>
  )
}

export default InfoBlock
