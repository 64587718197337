import React from "react"

import Banner from "../../blocks/banner"
import InfoImg from "../../blocks/infoImg/InfoImg"
import CallToActionBlockFirst from "../../blocks/call-to-action-block-first"
import InfoBlock from "../../blocks/info-cards"
import { Imgs } from "../../@types/common"
import infoImg1 from "../../assets/images/dataset/info1.svg"
import infoImg2 from "../../assets/images/dataset/info2.svg"
import actionImg from "../../assets/images/dataset/action.svg"
import card1Img from "../../assets/images/dataset/card1.svg"
import card2Img from "../../assets/images/dataset/card2.svg"
import card3Img from "../../assets/images/dataset/card3.svg"
import card4Img from "../../assets/images/dataset/card4.svg"
import card5Img from "../../assets/images/dataset/card5.svg"
import card6Img from "../../assets/images/dataset/card6.svg"
import bannerImg1 from "../../assets/images/dataset/banner1.svg"
import bannerImg2 from "../../assets/images/dataset/banner2.svg"

const bannerImages: Imgs = {
  bannerImg1,
  bannerImg2,
}

const cardImages: Imgs = {
  card1Img: card1Img,
  card2Img: card2Img,
  card3Img: card3Img,
  card4Img: card4Img,
  card5Img: card5Img,
  card6Img: card6Img,
}

const infoImages: Imgs = {
  infoImg1: infoImg1,
  infoImg2: infoImg2,
}

const actionImages: Imgs = {
  actionImg: actionImg,
}

const sections = [
  { component: (key: number, className: string, data: any) => <Banner data={data} className={className} key={key} imgs={bannerImages} /> },
  { component: (key: number, className: string, data: any) => <InfoImg data={data.data.content.dataset.Section3} className={className} key={key} images={infoImages} /> },
  { component: (key: number, className: string, data: any) => <InfoBlock data={data} className={className} key={key} images={cardImages} /> },
  { component: (key: number, className: string, data: any) => <CallToActionBlockFirst data={data} className={className} key={key} images={actionImages} /> },
]

const DataSetSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-sova-dataset_${i}`, data))}</>
}

DataSetSections.displayName = "DataSetSections"

export default DataSetSections
